<template>
    <div class="z-[100] flex h-16 bg-grind py-3">
        <button
            class="inline-flex items-center justify-center pl-2 md:w-24 md:px-0"
            @click.prevent="onLeave('/')"
        >
            <img
                src="~/assets/images/roastar_star_white.svg"
                alt="Roastar Logo"
                class="h-9 w-9"
            />
        </button>

        <button
            class="flex items-center text-sm font-semibold text-white no-underline md:text-[24px] hidden xl:block"
            @click.prevent="onLeave('/design-lab')"
        >
            Roastar Design Lab
        </button>

        <div class="flex-1 xl:pl-5">
            <slot />
        </div>

        <Modal ref="leaveConfirmationModal">
            <template #title>You are about to leave the Design Lab.</template>

            <p>
                You are about to exit the Design Lab and lose the progress
                you’ve made. Are you sure you want to leave?
            </p>

            <template #actions="{ close }">
                <button class="btn-secondary" @click="close">
                    No, finish my design
                </button>

                <NuxtLink class="btn-primary" :to="leaveTo" @click="close">
                    Yes, exit the Design Lab
                </NuxtLink>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import Modal from '~/components/Modal.vue';

const leaveConfirmationModal = ref<InstanceType<typeof Modal> | null>(null);
const leaveTo = ref();

const props = withDefaults(
    defineProps<{
        hasUnsavedChanges: boolean;
    }>(),
    {
        hasUnsavedChanges: false,
    },
);

const onLeave = (redirectTo: string) => {
    leaveTo.value = redirectTo;
    if (props.hasUnsavedChanges) {
        leaveConfirmationModal.value?.open();
    } else {
        navigateTo(leaveTo.value);
    }
};
</script>
