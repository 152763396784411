<template>
    <ais-current-refinements>
        <template #default="{ items }">
            <div class="flex flex-auto gap-3.5 text-center md:flex-col">
                <div
                    class="flex aspect-square flex-1 cursor-pointer flex-col items-center justify-center gap-1 rounded-lg bg-grind-100 p-4 text-grind no-underline ring-grind hover:ring-3 md:aspect-auto"
                    @click="actionClicked('upload', items)"
                >
                    <Icon
                        name="cloud-arrow-up-regular"
                        class="inline-block text-5xl md:text-[74px]"
                    />
                    <div class="text-sm sm:text-xl">Upload your own design</div>
                </div>
                <div
                    class="flex flex-1 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg bg-lighter-blue p-4 text-grind ring-grind hover:ring-3"
                    @click="actionClicked('ai', items)"
                >
                    <Icon
                        name="sparkles-regular"
                        class="inline-block text-4xl md:text-[64px]"
                    />
                    <div class="text-sm sm:text-xl">Create an AI design</div>
                </div>
            </div>
            <PickYourProductModal ref="pickYourProductModal" />
        </template>
    </ais-current-refinements>
</template>

<script setup lang="ts">
import { AisCurrentRefinements } from 'vue-instantsearch/vue3/es';
import PickYourProductModal from '~/components/page-building/design-lab-pick-your-product/components/PickYourProductModal.vue';
import { ref } from 'vue';
import {
    flatBottomBag,
    gussetBag,
    tinCan4Inch,
    tinCan5Inch,
    tinCan7Inch,
} from '~/data/designLab/productIds';

const pickYourProductModal = ref<InstanceType<
    typeof PickYourProductModal
> | null>(null);

const bagData = [
    {
        productId: gussetBag,
        name: 'Gusset Bag',
        subtitle: 'Holds 12-16oz | 25 for $64.75',
        src: '/images/design-lab/gusset.svg',
    },
    {
        productId: flatBottomBag,
        name: 'Flat Bottom Bag',
        subtitle: 'Holds 10-12oz | 100 for $426',
        src: '/images/design-lab/flat-bottom.svg',
    },
];

const tinCanData = [
    {
        productId: tinCan4Inch,
        name: '4in Straight Tin Can',
        subtitle: 'Holds 6-8oz | 1 for $15',
        src: '/images/design-lab/4-tin-can.svg',
    },
    {
        productId: tinCan5Inch,
        name: '5.5in Straight Tin Can',
        subtitle: 'Holds 8-12oz | 1 for $15',
        src: '/images/design-lab/5-tin-can.svg',
    },
    {
        productId: tinCan7Inch,
        name: '7in Straight Tin Can',
        subtitle: 'Holds 10-16oz | 1 for $15',
        src: '/images/design-lab/7-tin-can.svg',
    },
];

const actionClicked = (
    action: 'upload' | 'ai',
    algoliaRefinementsProxy: typeof Proxy,
) => {
    console.log('action clicked');

    let algoliaRefinementArray = [];

    if (isProxy(algoliaRefinementsProxy)) {
        algoliaRefinementArray = toRaw(algoliaRefinementsProxy);
    }

    let actionTaken = false;

    algoliaRefinementArray.forEach((refinementCategory) => {
        if (refinementCategory.attribute == 'product_type') {
            if (refinementCategory.refinements.length == 1) {
                switch (refinementCategory.refinements[0].value) {
                    case 'Gusset Bag':
                        // Direct link to Gusset
                        actionTaken = true;
                        navigateTo(
                            '/roastar-design-lab#product=' +
                                gussetBag +
                                '&method=' +
                            action,
                            {
                                external: true,
                                open: {
                                    target: "_blank",
                                },
                            },
                        );
                        break;
                    case 'Flat Bottom Bag':
                        // Direct link to Flat Bottom
                        actionTaken = true;
                        navigateTo(
                            '/roastar-design-lab#product=' +
                                flatBottomBag +
                                '&method=' +
                                action,
                            {
                                external: true,
                                open: {
                                    target: "_blank",
                                },
                            },
                        );
                        break;
                    case 'Tin Can':
                        // Modal with ONLY tin can options
                        actionTaken = true;
                        pickYourProductModal.value?.open(action, tinCanData);
                        break;
                }
            }
        }
    });

    if (!actionTaken) {
        // Modal with all options
        pickYourProductModal.value?.open(action, bagData.concat(tinCanData));
    }
};
</script>

<style scoped>
.ais-CurrentRefinements {
    margin-top: 0;
}
</style>
