<template>
    <div
        class="relative flex justify-center pt-[70px] sm:h-[595px] sm:pt-[80px]"
    >
        <div
            v-if="attributes.length > 0"
            class="absolute left-[64px] top-0 z-20 sm:left-[285px] sm:top-[0px]"
        >
            <AttributeButton
                class="z-20"
                :selected="number === 1"
                @click="selectNumber(1)"
            />
            <svg height="780" width="500" class="absolute left-0 top-0">
                <polyline
                    points="23,50 23,355"
                    class="hidden fill-transparent stroke-grind stroke-2 sm:block"
                />
            </svg>
            <svg height="780" width="500" class="absolute left-0 top-0">
                <polyline
                    points="23,50 23,166"
                    class="fill-transparent stroke-grind stroke-2 sm:hidden"
                />
            </svg>
        </div>
        <div
            v-if="attributes.length > 1"
            class="absolute right-[-30px] top-[135px] z-20 sm:right-[38px] sm:top-[350px] xl:right-[70px]"
        >
            <AttributeButton
                class="z-20"
                :selected="number === 2"
                @click="selectNumber(2)"
            />
            <svg
                height="150"
                width="300"
                class="absolute right-0 top-0 hidden sm:block"
            >
                <polyline
                    points="278,50 278,110 130,110"
                    class="fill-transparent stroke-grind stroke-2"
                />
            </svg>
            <svg
                height="150"
                width="300"
                class="absolute right-0 top-0 sm:hidden"
            >
                <polyline
                    points="278,50 278,70 207,70"
                    class="fill-transparent stroke-grind stroke-2"
                />
            </svg>
        </div>
        <div
            v-if="attributes.length > 2"
            class="absolute -left-[40px] bottom-0 z-20 sm:bottom-[30px] sm:left-0 xl:left-[32px]"
        >
            <AttributeButton
                class="z-20"
                :selected="number === 3"
                @click="selectNumber(3)"
            />
            <svg
                height="150"
                width="300"
                class="absolute -left-[4px] bottom-0 hidden sm:block"
            >
                <polyline
                    points="30,100 30,50 207,50"
                    class="fill-transparent stroke-grind stroke-2"
                />
            </svg>
            <svg
                height="150"
                width="106"
                class="absolute -left-[4px] bottom-0 sm:hidden"
            >
                <polyline
                    points="30,100 30,64 105,64"
                    class="fill-transparent stroke-grind stroke-2"
                />
            </svg>
        </div>
        <img
            v-if="image"
            class="z-10 h-[219px] w-[175px] bg-transparent sm:h-[625px] sm:w-[625px]"
            :src="image.src"
            :alt="image.alt"
        />
    </div>
</template>

<script setup lang="ts">
import ProductAnatomyAttributeType from '~/types/ProductAnatomyAttributeType';
import ImageType from '~/types/ImageType';
import AttributeButton from '~/components/page-building/product-anatomy/components/AttributeButton.vue';

const emit = defineEmits(['setNumber']);

const selectNumber = (number: number) => {
    emit('setNumber', number);
};

defineProps<{
    image: ImageType;
    attributes: ProductAnatomyAttributeType[];
    number: number;
}>();
</script>
