import { ComponentPublicInstance, createApp } from 'vue';
import Tooltip from '~/components/ArtboardTooltip.vue';

export default defineNuxtPlugin(() => {
    const tooltipApp = createApp(Tooltip);
    const tooltipDiv = document.createElement('div');
    document.body.appendChild(tooltipDiv);

    const tooltipInstance = tooltipApp.mount(
        tooltipDiv,
    ) as ComponentPublicInstance<{
        showTooltip: (
            content: string,
            el: HTMLElement,
            autoHide: boolean,
        ) => void;
        hideTooltip: (delay: number) => void;
    }>;

    return {
        provide: {
            showTooltip: (
                content: string,
                el: HTMLElement,
                autoHide: boolean,
            ) => tooltipInstance.showTooltip(content, el, autoHide),
            hideTooltip: (delay = 600) => tooltipInstance.hideTooltip(delay),
        },
    };
});
