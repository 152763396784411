<template>
    <div class="sticky top-0 z-[999998]">
        <ClientOnly>
            <EnvironmentAlert />
            <ImpersonationAlert />
            <AnnouncementBanner v-if="announcementBannerEnabled" />
        </ClientOnly>
    </div>
</template>
5
<script setup lang="ts">
import EnvironmentAlert from '~/components/alerts/EnvironmentAlert.vue';
import ImpersonationAlert from '~/components/alerts/ImpersonationAlert.vue';
import AnnouncementBanner from '~/components/alerts/AnnouncementBanner.vue';

const route = useRoute();

const announcementBannerEnabled = computed(() => !route.meta.isDesignLab);
</script>
