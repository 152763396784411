<template>
    <BlogArticleGrid v-editable="blok" :data="blogData" />
</template>

<script setup lang="ts">
import ImageType from '~/types/ImageType';
import ArticleCardType from '~/types/ArticleCardType';
import StoryblokClient from 'storyblok-js-client';
import BlogArticleGridType from '~/types/BlogArticleGridType';
import BlogArticleGrid from '~/components/page-building/blog/BlogArticleGrid.vue';
import ArticleCategoryType from '~/types/ArticleCategoryType';
import { useArticleStructuredData } from '~/composables/useStructuredData';
import { stripTrailingSlash } from '~/utils/helpers';

const route = useRoute();
const config = useRuntimeConfig();

const slug = computed<string>(() => {
    if (Array.isArray(route.params.slug)) {
        return '';
    }
    return route.params.slug;
});

const client = new StoryblokClient({
    accessToken: config.public.storyblok.accessToken,
    region: 'us',
});

const categoryResponse = await client.get(`cdn/stories`, {
    per_page: 100,
    content_type: 'page',
    by_slugs: 'blog/category/*',
    sort_by: 'name:asc',
    filter_query: {
        component: { in: 'page' },
    },
});
const articleResponse = await client.get(`cdn/stories`, {
    per_page: 100,
    by_slugs: 'blog/*',
    filter_query: {
        component: { in: 'article' },
        categories: slug.value !== '' ? { in_array: slug.value } : null,
    },
});

const categoryData = categoryResponse.data;
const articleData = articleResponse.data;

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const categories = computed<ArticleCategoryType[]>(() => {
    const categories: ArticleCategoryType[] = [];
    Object.values(categoryData.stories).forEach((story: any) => {
        categories.push({
            id: story.slug,
            label: capitalizeFirstLetter(story.slug.replace(/-/g, ' ')),
            slug: stripTrailingSlash(story.full_slug),
        } as ArticleCategoryType);
    });
    categories.unshift({
        id: 'all',
        label: 'All',
        slug: 'blog',
    });
    return categories;
});

const articles = computed<ArticleCardType[]>(() => {
    const articles: ArticleCardType[] = [];
    Object.values(articleData.stories).forEach((story: any) => {
        let featuredArticleName = '';
        if (props.blok.featured_article) {
            featuredArticleName = props.blok.featured_article.name;
        }
        if (featuredArticleName !== story.name) {
            articles.push({
                image: {
                    title: story.content.image.title,
                    alt: story.content.image.alt,
                    src: story.content.image.filename,
                } as ImageType,
                categories: story.content.categories
                    ? story.content.categories
                    : [],
                date: story.content.date,
                title: story.content.title,
                description: richTextToHtml(
                    story.content.description,
                ) as string,
                shortDescription: richTextToHtml(
                    story.content.short_description,
                ) as string,
                slug: '/blog/' + story.slug,
            } as ArticleCardType);
        }
    });
    return articles;
});

articles.value.sort((a, b) => {
    return compare(b.date, a.date);
});

const structuredData = useArticleStructuredData(articles.value);

useServerHead(() => ({
    script: structuredData,
}));

const blogData = computed<BlogArticleGridType>(() => {
    return {
        heading: props.blok.heading,
        categories: categories.value,
        currentCategory: slug.value ? slug.value : 'all',
        featuredArticle: props.blok.featured_article
            ? ({
                  image: {
                      title: props.blok.featured_article.content.image.title,
                      alt: props.blok.featured_article.content.image.alt,
                      src: props.blok.featured_article.content.image.filename,
                  } as ImageType,
                  categories: props.blok.featured_article.content.categories,
                  date: props.blok.featured_article.content.date,
                  title: props.blok.featured_article.content.title,
                  shortDescription: richTextToHtml(
                      props.blok.featured_article.content.short_description,
                  ) as string,
                  description: richTextToHtml(
                      props.blok.featured_article.content.description,
                  ) as string,
                  slug: '/blog/' + props.blok.featured_article.slug,
                  vEditable: props.blok.featured_article,
              } as ArticleCardType)
            : null,
        articles: articles.value,
        showCta: props.blok.show_cta,
        ctaHeading: props.blok.cta_heading,
        ctaText: richTextToHtml(props.blok.cta_text) as string,
        ctaButtonLabel: props.blok.cta_button_label,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
        vEditable: props.blok,
    } as BlogArticleGridType;
});
</script>
