<template>
    <NuxtLink :to="link" target="_blank" class="group text-grind no-underline">
        <div
            class="relative mb-2 aspect-square rounded-lg border border-grind-200 ring-inset ring-grind transition group-hover:border-grind group-hover:ring-2"
        >
            <Icon
                v-if="theme.is_color_customizable"
                name="paintbrush-solid"
                class="bg-color-wheel absolute left-3.5 top-3.5 flex aspect-square w-8 items-center justify-center rounded-sm text-xl text-white"
            />
            <StoryblokImage
                :src="theme.image.src || '/images/image-placeholder.svg'"
                :alt="theme.image.alt ? theme.image.alt : 'Product Image'"
                class="h-full w-full rounded-t-[10px] object-contain"
                :class="{ 'm-auto p-4': !theme.image.src }"
            />
        </div>
        <div class="text-base">
            <div class="font-bold">
                {{ theme.display_name }}
            </div>
            <div>{{ theme.minimum }} from ${{ theme.price_starts_at }}</div>
        </div>
    </NuxtLink>
</template>

<script setup lang="ts">
import AlgoliaDesignLabTheme from '~/types/AlgoliaDesignLabTheme';

const props = defineProps<{
    theme: AlgoliaDesignLabTheme;
}>();

const link = computed<string>(() => {
    return (
        '/roastar-design-lab#product=' +
        props.theme.product_id +
        '&theme=' +
        props.theme.slug
    );
});
</script>
