<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="
            data.nextBackgroundColor ? data.nextBackgroundColor : null
        "
        :arrow-callout="data.arrowCallout"
    >
        <div class="relative flex flex-col gap-x-8 py-28 lg:flex-row">
            <div class="order-1 mb-7 lg:order-none lg:hidden">
                <h2 class="mb-7">{{ data.heading }}</h2>
                <WysiwygContent :content="data.text" />
            </div>
            <div class="order-2 flex items-center lg:order-1 lg:w-5/12">
                <div class="w-full">
                    <div class="order-1 mb-7 hidden lg:order-none lg:block">
                        <h2 class="mb-7">{{ data.heading }}</h2>
                        <WysiwygContent :content="data.text" />
                    </div>
                    <Card id="anatomy-details-card" class="mb-7 p-7">
                        <div v-if="data.attributes[selectedNumber - 1]">
                            <div class="flex gap-x-2 pb-4">
                                <div class="flex flex-shrink-0 items-center">
                                    <img
                                        src="~/assets/images/roastar_star_black.svg"
                                        width="30"
                                        height="30"
                                        alt="Roastar logo"
                                    />
                                </div>
                                <h3>
                                    {{
                                        data.attributes[selectedNumber - 1]
                                            .heading
                                    }}
                                </h3>
                            </div>
                            <WysiwygContent
                                :content="
                                    data.attributes[selectedNumber - 1]
                                        .description
                                "
                                class="overflow-y-auto border-t border-gray-300 pr-4 pt-4 lg:max-h-[165px]"
                            />
                        </div>
                    </Card>
                    <div v-if="data.buttons" class="hidden lg:block">
                        <Button
                            v-for="(button, key) in data.buttons"
                            :key="key"
                            :button="button"
                            class="w-full lg:w-auto"
                        />
                    </div>
                </div>
            </div>
            <div
                class="order-2 flex justify-center pb-24 lg:order-3 lg:w-7/12 lg:pb-0"
            >
                <GussetedBagAnatomy
                    v-if="data.type === 'gusseted-bag'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <FlatBottomPouchAnatomy
                    v-if="data.type === 'flat-bottom-pouch'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <StandUpPouchAnatomy
                    v-if="data.type === 'stand-up-pouch'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <FlatPouchAnatomy
                    v-if="data.type === 'flat-pouch'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <TinCanAnatomy
                    v-if="data.type === 'tin-can'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <BeverageLabelAnatomy
                    v-if="data.type === 'beverage-label'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <StickerAnatomy
                    v-if="data.type === 'sticker'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <PaperTubeAnatomy
                    v-if="data.type === 'paper-tube'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <BagTapeAnatomy
                    v-if="data.type === 'bag-tape'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
                <BagLabelAnatomy
                    v-if="data.type === 'bag-label'"
                    :image="data.image"
                    :number="selectedNumber"
                    :attributes="data.attributes"
                    @set-number="setSelectedNumber"
                />
            </div>
            <div v-if="data.buttons" class="order-last lg:hidden">
                <Button
                    v-for="(button, key) in data.buttons"
                    :key="key"
                    :button="button"
                    class="w-full lg:w-auto"
                />
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import Button from '~/components/page-building/components/Button.vue';
import BlockContainer from '~/components/BlockContainer.vue';
import Card from '~/components/page-building/components/Card.vue';
import ProductAnatomyType from '~/types/ProductAnatomyType';
import TinCanAnatomy from '~/components/page-building/product-anatomy/components/product-types/TinCanAnatomy.vue';
import BeverageLabelAnatomy from '~/components/page-building/product-anatomy/components/product-types/BeverageLabelAnatomy.vue';
import StickerAnatomy from '~/components/page-building/product-anatomy/components/product-types/StickerAnatomy.vue';
import PaperTubeAnatomy from '~/components/page-building/product-anatomy/components/product-types/PaperTubeAnatomy.vue';
import FlatBottomPouchAnatomy from '~/components/page-building/product-anatomy/components/product-types/FlatBottomPouchAnatomy.vue';
import GussetedBagAnatomy from '~/components/page-building/product-anatomy/components/product-types/GussetedBagAnatomy.vue';
import StandUpPouchAnatomy from '~/components/page-building/product-anatomy/components/product-types/StandUpPouchAnatomy.vue';
import FlatPouchAnatomy from '~/components/page-building/product-anatomy/components/product-types/FlatPouchAnatomy.vue';
import BagTapeAnatomy from '~/components/page-building/product-anatomy/components/product-types/BagTapeAnatomy.vue';
import BagLabelAnatomy from '~/components/page-building/product-anatomy/components/product-types/BagLabelAnatomy.vue';

const selectedNumber = ref<number>(1);

defineProps<{
    data: ProductAnatomyType;
}>();

const setSelectedNumber = (number: number) => {
    selectedNumber.value = number;
};
</script>
