<template>
    <div
        v-show="visible"
        ref="tooltipRef"
        class="pointer-events-none absolute z-[100000000] flex origin-center items-start gap-2 rounded-md bg-white px-3 py-2 text-sm leading-normal text-grind shadow-[0px_0px_6px_0px_rgba(0,_0,_0,_0.1)]"
        :style="{
            top: `${position.y}px`,
            left: `${position.x}px`,
        }"
    >
        <span v-html="content"> </span>
        <button
            id="tooltip-close-button"
            aria-label="Close"
            class="pointer-events-auto transition-colors hover:text-brew focus:outline-none lg:hidden"
            @click.prevent="tooltipState.visible = false"
        >
            <svg
                width="24"
                height="24"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.6272 3.82422C19.3434 3.0918 19.3434 1.90234 18.6272 1.16992C17.9111 0.4375 16.748 0.4375 16.0319 1.16992L9.99909 7.3457L3.96055 1.17578C3.2444 0.443359 2.08138 0.443359 1.36523 1.17578C0.649089 1.9082 0.649089 3.09766 1.36523 3.83008L7.40378 10L1.37096 16.1758C0.654818 16.9082 0.654818 18.0977 1.37096 18.8301C2.08711 19.5625 3.25013 19.5625 3.96628 18.8301L9.99909 12.6543L16.0376 18.8242C16.7538 19.5566 17.9168 19.5566 18.6329 18.8242C19.3491 18.0918 19.3491 16.9023 18.6329 16.1699L12.5944 10L18.6272 3.82422Z"
                    fill="#101820"
                />
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">
const tooltipRef = ref<HTMLElement | null>(null);
const tooltipState = reactive({
    visible: false,
    content: '',
    position: { x: 0, y: 0 },
});

let hideTimeout: ReturnType<typeof setTimeout> | null = null;

const showTooltip = async (
    content: string,
    el: HTMLElement,
    autoHide = false,
) => {
    if (hideTimeout) {
        clearTimeout(hideTimeout);
        hideTimeout = null;
    }

    tooltipState.content = content;

    const hiddenElement = document.createElement('div');
    hiddenElement.style.position = 'absolute';
    hiddenElement.style.visibility = 'hidden';
    hiddenElement.style.top = '-9999px';
    hiddenElement.innerHTML = content;
    document.body.appendChild(hiddenElement);

    const { offsetWidth, offsetHeight } = hiddenElement;

    const bounds = el.getBoundingClientRect();
    tooltipState.position = {
        x: bounds.x - offsetWidth / 2,
        y: bounds.y - offsetHeight - 20,
    };

    tooltipState.position.x = Math.max(
        0,
        Math.min(window.innerWidth - offsetWidth, tooltipState.position.x),
    );
    tooltipState.position.y = Math.max(
        0,
        Math.min(window.innerHeight - offsetHeight, tooltipState.position.y),
    );

    tooltipState.visible = true;

    document.body.removeChild(hiddenElement);

    if (autoHide) {
        await hideTooltip(7000);
    }
};

const hideTooltip = async (delay = 600) => {
    if (hideTimeout) {
        clearTimeout(hideTimeout);
    }

    hideTimeout = setTimeout(() => {
        tooltipState.visible = false;
    }, delay);
};

const { visible, content, position } = toRefs(tooltipState);

defineExpose({
    showTooltip,
    hideTooltip,
});
</script>
